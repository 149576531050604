import { PersonView } from './components'
import { customBlocks } from 'components/manage/Blocks';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';


export default function applyConfig(config) {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['de'],
    defaultLanguage: 'de',
    socialNetworks: [
      {
        id: 'facebook',
        title: 'Facebook',
        icon: 'facebook',
        url: 'https://www.facebook.com/HerrmannAG.Langnau',
      },
      {
        id: 'linkedin',
        title: 'LinkedIn',
        icon: 'linkedin',
        url: 'https://ch.linkedin.com/company/satz-druck-herrmann-ag',
      },
      {
        id: 'tiktok',
        title: 'TikTok',
        icon: 'tiktok',
        url: 'https://www.tiktok.com/@herrmann.ag',
      },
    ],
  };

  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      person: PersonView,
    },
  };

  config.settings.image_crop_aspect_ratios = [
    {
      label: '16:9',
      ratio: 16 / 9,
    },
    {
      label: '4:3',
      ratio: 4 / 3,
    },
    {
      label: '1:1',
      ratio: 1,
    },
    {
      label: 'Header',
      ratio: 10 / 1,
    },
  ];

  config.settings.themeColors = [
    { value: 'white', title: 'No theme' },
    { value: 'primary', title: 'Primary' },
    { value: 'secondary', title: 'Secondary' },
    { value: 'tertiary', title: 'Tertiary' },
  ];

  config.blocks.blocksConfig.slider = {
    ...config.blocks.blocksConfig.slider,
    referenceContainerQuery: '.container .header',
  };

  config.settings.DSGVOBanner = {
    ...(config.settings.DSGVOBanner || {}),
    trackingId: 'G-NTK6GFRG6D',
    modules: ['google', 'youtube'],
    privacy_url: '/datenschutz',
  };

  config.settings.available_colors = [
    //Blue tones
    '#cbeef3',
    '#bfdaeb',
    '#a2c5d3',
    '#6292a9',
    '#1e5c7a',
    '#113c52',
    //Green tones
    '#a3b18a',
    '#4f772d',
    '#588157',
    '#3a5a40',
    '#344e41',
    '#656d4a',
    //Red tones
    '#ffcad4',
    '#ff758f',
    '#a53860',
    '#b21e35',
    '#85182a',
    '#6e1423',
    //Yellow tones
    '#fad643',
    '#dbb42c',
    '#fdb833',
    '#ffa600',
    '#ff8c00',
    '#ff8000',
    //Brown tones
    '#e6ccb2',
    '#ddb892',
    '#b08968',
    '#7f5539',
    '#9c6644',
    '#583101',
    //Gray tones
    '#e9ecef',
    '#ced4da',
    '#adb5bd',
    '#6c757d',
    '#000000',
    '#ffffff',
    // Other colors
    '#D07E8A',
    '#202B3A',
    '#565C6E',
  ];

  config.blocks = {
    ...config.blocks,
    requiredBlocks: [],
    blocksConfig: { ...config.blocks.blocksConfig, ...customBlocks },
    groupBlocksOrder: [
      ...(config.blocks.groupBlocksOrder),
      { id: 'special', title: 'Special' },
    ]
  };

  /* Configure block categories  */
  config.blocks.blocksConfig = {
    ...(config.blocks.blocksConfig),
    __button: {
      ...(config.blocks.blocksConfig.__button),
      mostUsed: false,
    },
    maps: {
      ...(config.blocks.blocksConfig.maps),
      mostUsed: true,
    },
    columnsBlock: {
      ...(config.blocks.blocksConfig.columnsBlock),
      mostUsed: true,
    },
    dsgvoBanner: {
      ...(config.blocks.blocksConfig.dsgvoBanner),
      group: 'special',
    },
    follow_us: {
      ...(config.blocks.blocksConfig.follow_us),
      group: 'special',
      title: 'Social Links',
    },
  };

  /* Configure available block types */
  config.blocks.blocksConfig = {
    ...(config.blocks.blocksConfig),
    title: {
      ...(config.blocks.blocksConfig.title),
      restricted: true,
    },
    description: {
      ...(config.blocks.blocksConfig.description),
      restricted: true,
    },
    toc: {
      ...(config.blocks.blocksConfig.toc),
      restricted: false,
    },
    search: {
      ...(config.blocks.blocksConfig.search),
      restricted: true,
    },
    slider: {
      ...(config.blocks.blocksConfig.slider),
      restricted: true,
    },
    carousel: {
      ...(config.blocks.blocksConfig.carousel),
      restricted: true,
    },
    teaser: {
      ...(config.blocks.blocksConfig.teaser),
      restricted: true,
    },
    listing: {
      ...(config.blocks.blocksConfig.listing),
      restricted: true,
    },
    tabs_block: {
      ...(config.blocks.blocksConfig.tabs_block),
      group: 'special',
    },
  };

  return config;
}